import { CryptoUtils } from './CryptoUtils'
import { VxViewerParamTP } from '../types/VxViewerParamTP'

/**
 */
export class ViewerUtils {

    /**
     */
    static convertParamsToJson(): VxViewerParamTP | undefined {

        const params = new URLSearchParams(window.location.search)?.get('p')

        if (!params)
            return

        const decryptedParams = CryptoUtils.base64UrlDecode(params)
        try {
            const paramsInJson = JSON.parse(decryptedParams) as VxViewerParamTP

            if (!paramsInJson.viewBy || !paramsInJson.token || !paramsInJson.examId || !paramsInJson.patientName || !paramsInJson.examDescription)
                return

            return paramsInJson

        } catch (e) {
            console.log('Erro ao converter parametros', e)
            return
        }
    }

}
