import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import NavBar from '../NavBar'
import Svg from '../Svg'
import Icon from '../Icon'
import IconButton from '../IconButton'
import Dropdown from '../Dropdown'
import { ViewerUtils } from '../../../../../vx/src/utils/ViewerUtils'

function Header({
                    children,
                    menuOptions,
                    isReturnEnabled,
                    onClickReturnButton,
                    isSticky,
                    WhiteLabeling,
                    ...props
                }): ReactNode {
    const {t} = useTranslation('Header')

    // TODO: this should be passed in as a prop instead and the react-router-dom
    // dependency should be dropped
    const onClickReturn = () => {
        if (isReturnEnabled && onClickReturnButton) {
            onClickReturnButton()
        }
    }


    // PARAMETROS VX
    const params = ViewerUtils.convertParamsToJson()

    return (
        <NavBar
            className="justify-between border-b-4 border-black"
            isSticky={isSticky}
        >
            <div className="flex flex-1 justify-between">
                <div className="flex items-center">
                    {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
                    <div
                        className={classNames(
                            'mr-3 inline-flex items-center',
                            isReturnEnabled && 'cursor-pointer'
                        )}
                        onClick={onClickReturn}
                        data-cy="return-to-work-list"
                    >
                        {isReturnEnabled && (
                            <Icon
                                name="chevron-left"
                                className="text-primary-active w-8"
                            />
                        )}
                        <div className="ml-4">
                            {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo-ohif"/>}
                        </div>
                    </div>
                </div>

                <div className="flex items-center">
                    <span className="text-common-light mr-3 text-lg">{ params.examDescription }</span>
                </div>

                <div className="flex items-center">{children}</div>

                <div className="flex items-center">
                    <span className="text-common-light mr-3 text-lg">{ params.patientName }</span>
                </div>

                <div className="flex items-center">
                    <Dropdown
                        id="options"
                        showDropdownIcon={false}
                        list={menuOptions}
                        alignment="right"
                    >
                        <IconButton
                            id={'options-settings-icon'}
                            variant="text"
                            color="inherit"
                            size="initial"
                            className="text-primary-active"
                        >
                            <Icon name="settings"/>
                        </IconButton>
                        <IconButton
                            id={'options-chevron-down-icon'}
                            variant="text"
                            color="inherit"
                            size="initial"
                            className="text-primary-active"
                        >
                            <Icon name="chevron-down"/>
                        </IconButton>
                    </Dropdown>
                </div>
            </div>
        </NavBar>
    )
}

Header.propTypes = {
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            onClick: PropTypes.func.isRequired,
        })
    ),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    isReturnEnabled: PropTypes.bool,
    isSticky: PropTypes.bool,
    onClickReturnButton: PropTypes.func,
    WhiteLabeling: PropTypes.object,
}

Header.defaultProps = {
    isReturnEnabled: true,
    isSticky: false,
}

export default Header
