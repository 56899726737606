/**
 */
export class CryptoUtils {

    /**
     * Cria um hash reversivel encriptado.
     */
    public static reversibleEncrypt(text): string {
        return Buffer.from(text).toString('base64')
    }

    /**
     * Descripta um hash reversivel
     */
    public static decrypt(text): string {
        return Buffer.from(text, 'base64').toString('utf8')
    }

    public static base64UrlEncode (input: string): string {
        return Buffer.from(input, 'utf-8')
            .toString('base64')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    public static base64UrlDecode (input: string): string {
        let unpaddedInput = input.replace(/-/g, '+').replace(/_/g, '/');
        const padding = input.length % 4;
        if (padding !== 0) {
            unpaddedInput += '='.repeat(4 - padding);
        }
        return Buffer.from(unpaddedInput, 'base64').toString('utf-8');
    }
}
