import { RequestConfigTP } from '../types/RequestConfigTP'
import { HttpMethodEnum } from '../types/HttpMethodEnum'

export class ExamsDownloadsRequests {

	public static CONTROLLER_ROOT = 'exams-downloads'
	public static PREPARE_VIEWER_EP = 'prepare-viewer/:examId'

	static prepareViewer = (examId: string): RequestConfigTP<void> => ({
		url: `${ExamsDownloadsRequests.CONTROLLER_ROOT}/${ExamsDownloadsRequests.PREPARE_VIEWER_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})
}
