import React, { useEffect, useState } from 'react'
import { ProgressLoadingBar } from '@ohif/ui'
import { RequestHelper } from '../../utils/RequestHelper'
import { ExamsDownloadsRequests } from '../../requests/ExamsDownloadsRequests'
import { RequestConfigTP } from '../../types/RequestConfigTP'
import { PatientPortalRequests } from '../../requests/PatientPortalRequests'
import { VxViewerParamStatusTP } from 'vx/src/types/VxViewerParamStatusTP'
import { ViewerUtils } from '../../utils/ViewerUtils'

export function WrapperAuthenticationCP({children}) {

    const [view, setView] = useState<VxViewerParamStatusTP>({status: 'loading'})

    useEffect(init, [])

    /**
     */
    function init(): void {

        const params = ViewerUtils.convertParamsToJson()
        if (!params) {
            setView({status: 'error', reason: 'Parâmetros obrigatórios não informados'})
            return
        }

        let requestConfig: RequestConfigTP

        if (params.viewBy === 'user')
            requestConfig = ExamsDownloadsRequests.prepareViewer(params.examId)
        else
            requestConfig = PatientPortalRequests.prepareViewer(params.examId)

        requestConfig.headers = [{headerName: 'Authorization', headerValue: `Bearer ${params.token}`}]
        RequestHelper.runRequest(requestConfig).then((result) => {

            if (result.status !== 200) {
                setView({ status: 'error', reason: 'Você não tem autorização para visualizar o exame OU o link expirou' })
                return
            }

            setView({status: 'ok'})

        }).catch(() => {
            setView({ status: 'error', reason: 'Você não tem autorização para visualizar o exame OU o link expirou' })
        })
    }

    return (
        <>
            {
                view.status === 'loading' &&
                <ProgressLoadingBar/>
            }
            {
                view.status === 'ok' &&
                children
            }
            {
                view.status === 'error' &&
                <div
                    style={{color: 'white', display: 'flex', justifyContent: 'center', marginTop: 50, fontSize: 30}}
                >
                    {view.reason ?? ''}
                </div>
            }
        </>
    )
}
