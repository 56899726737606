import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpMethodEnum } from '../types/HttpMethodEnum'
import { RequestConfigTP } from '../types/RequestConfigTP'
import { AppConfig } from '../utils/AppConfig'

/**
 * Encapsula metodos para gestao de requisicoes http.
 */
export class RequestHelper {

    private static readonly _DEFAULT_RESPONSE = 'json'

    /** Executa 01 requisicao http generica parametrizada. */
    static async runRequest<ResponseTP = any>(
        config: RequestConfigTP,
        param2?: string | (() => void) | boolean,
        requestId?: string
    ): Promise<AxiosResponse<ResponseTP>> {

        let requestParams: AxiosRequestConfig | undefined
        try {
            requestParams = {
                url: `${AppConfig.getInstance().apiBaseUrl}/${config.url}`,
                method: config.method,
                headers: this._getRequestHeaders(config),
                params: (config.method === HttpMethodEnum.GET) ? config.params : undefined,
                data: (config.method !== HttpMethodEnum.GET) ? config.params : undefined,
                responseType: config.responseType ?? this._DEFAULT_RESPONSE,
                maxContentLength: Infinity
            }

            return await axios.request<ResponseTP>(requestParams)

        } catch (error) {
            console.log('error api', error)
        }
    }

    /** Gera & retorna headers para envio de 01 requisicao. */
    static _getRequestHeaders(config?: RequestConfigTP): {} {

        const headerLists = [(config?.headers ?? [])]
        const hasNoAuth = config?.noAuth ?? false
        const headers: any = {}

        for (const headersList of headerLists) {
            headersList.forEach(header => {
                if (!hasNoAuth || header.headerName !== 'Authorization')
                    headers[header.headerName] = header.headerValue
            })
        }

        return headers
    }
}
