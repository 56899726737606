import { RequestConfigTP } from '../types/RequestConfigTP'
import { HttpMethodEnum } from '../types/HttpMethodEnum'

export class PatientPortalRequests {

	public static CONTROLLER_ROOT = 'patient-portal'
    public static PREPARE_VIEWER_EP = 'prepare-viewer/:examId'

    static prepareViewer = (examId: string): RequestConfigTP<void> => ({
        url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.PREPARE_VIEWER_EP.replace(':examId', examId)}`,
        method: HttpMethodEnum.GET,
    })
}
